
export const DAO = process.env.REACT_APP_DAO;
export const STAKING_FACTORY = process.env.REACT_APP_STAKING_FACTORY;
export const WETH = process.env.REACT_APP_WETH;
export const NDX = process.env.REACT_APP_NDX;
export const CONTROLLER = process.env.REACT_APP_CONTROLLER;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const UNISWAP_ROUTER = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

export const META_GOVERNOR_COMP = '0xeEB40d10cb86f569BB978A81a4eA178a9E1dfDdf';
export const META_GOVERNOR_UNI = '0x1aa7c2a1E6E0c4981D77b96aB985b161bb7729b9';
export const PROXY_MANAGER = '0xD23DeDC599bD56767e42D48484d6Ca96ab01C115'

export const MULTI_TOKEN_STAKING = (process.env.REACT_APP_ETH_NETWORK === 'mainnet' ? '0xC46E0E7eCb3EfCC417f6F89b940FFAFf72556382' : '0xd4Ca58850bf7814660fb55234EF1fCBDF5c04D60').toLowerCase();